
import React from 'react';
import {useDispatch} from "react-redux"
import { Link, graphql } from "gatsby";
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import styles from "../../components/innerPage.module.css";
import Layout from '../../components/Investor/layout';
import {  isNullUndefined, addDisclaimersToCmsLinks, richTextOptions, capitalizeFirstLetter, transformMarkdownToReact, replaceNewlinesWithHtmlLineBreaks } from "../../util"
import {showInvestorDisclaimerPopup} from "../../actions/investor/prompts/disclaimers"
import {toggleInvestorDisclaimerPopup} from "../../controllers/investor/disclaimer"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import { getDefaultInvestorDisclaimerPopupConfigs, config } from '../../util/configs'
import queryString from "query-string"
import SEO from "../../components/seo"
import { ValidateRoute } from '../../util/route-helper';
import TemplateWrapper from "../../components/TemplateWrapper"

export const pageQueryAccordionTemplateInvestor = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulAccordionTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
            bannerImage {
                file {
                    url
                }
            }
            spaceId
            slug
            title
            bodyText {
                json
            }
            accordion {
                title
                description
            }
        }
    }
`

const AccordionTemplatePageInvestor = (props) => {

  const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'block',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);

  const dispatch = useDispatch()

  const {bannerImage, slug, title, bodyText, accordion} = props.data.contentfulAccordionTemplate;

  let latestYear = accordion.map(item => `${item.title}`).sort((a, b) => (b - a));

  const [expanded, setExpanded] = React.useState(latestYear[0]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  let richText = documentToReactComponents(isNullUndefined(bodyText) ? bodyText : bodyText.json, richTextOptions)

  const showExternalLinkDisclaimer = (externalUrl) => {
    let okCallback = () => window.open (externalUrl, "_blank")
    let closeCallback = () => {}
    let actionConfig = getDefaultInvestorDisclaimerPopupConfigs(okCallback, closeCallback)
    dispatch(showInvestorDisclaimerPopup(actionConfig));
  }

  let bodyHtml = ReactDOMServer.renderToStaticMarkup(richText)

  let [veevaDisclaimer, setVeevaDisclaimer] = React.useState(false);

    React.useEffect(() => {

        let {veevaDisclaimer, veevaTab} = queryString.parse(props.location.search || "");
  
        if(veevaDisclaimer){
          setVeevaDisclaimer(true);
          showExternalLinkDisclaimer(config.defaultBaseUrl)
        }

        if(veevaTab){
          setExpanded(`${veevaTab}`)
        }
  
    }, []);

  return (
    <TemplateWrapper>
      <Layout>
    <SEO title={`${capitalizeFirstLetter(title)} | Investor or Media Relations`} />
    <div className={styles.banner} style={{background:`url(${isNullUndefined(bannerImage) ? "":bannerImage.file.url})`}}>
    <div className={styles.overlay}></div>
                      <div className="container">
                         <div className="row">
                             <div className="col-12 col-sm-12 col-md-12">
                              
                             <h1 className="">{title}</h1>
                             <ol className="custom_breadcrumb">
                             <li className="breadcrumb-item"><Link to="/Investor/home">Home</Link></li>
                                  <li className="breadcrumb-item"><a href="#">About</a></li>
                                  <li className="breadcrumb-item active" aria-current="page">{title}</li>
                              </ol>
                             </div>
                         
                             </div>
                      </div>
          </div>

          <div className="container mb-5">
            

                        {ReactHtmlParser(bodyHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}
            
            <h3>{title}:</h3>




            
            {accordion.map((item, index) => {
                return (
                    <Accordion square expanded={expanded === `${item.title}`} onChange={handleChange(`${item.title}`)}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>{item.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordion_content_wrap">
                        <Typography>
                        
                        <div dangerouslySetInnerHTML={{__html: replaceNewlinesWithHtmlLineBreaks(item.description)}} />
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                )
            })}
    

      
   
    </div>
</Layout>
    </TemplateWrapper>
  );
}

export default AccordionTemplatePageInvestor;
